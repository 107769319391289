import {
  Box,
  Container,
  Group,
  Button,
  Badge,
  Card,
  Image,
  Text,
  ActionIcon,
  AppShell,
  Divider,
  Paper,
  SimpleGrid,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { ContentBase } from '../common/content/ContentBase';
import { useLanguage } from '../common/language/Language';
import useSWR from 'swr';
import { ContentItem } from '../common/content/api/Content';
import { fetcher } from '../common/Fetcher';
import { renderMarkdown } from '../common/content/RenderMarkdown';
import { duotone, light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { ActionItem, AppMenu } from '../modules/training/components/AppMenu';

export const Landing = () => {
  const lang = useLanguage();
  let navigate = useNavigate();

  const actions: ActionItem[] = [
    {
      title: '',
      action: '/',
      icon: duotone('home'),
    },
    {
      title: '',
      action: '/rounds',
      icon: duotone('fire'),
    },
    // {
    //     title: '',
    //     action: '/contact',
    //     icon: duotone('fire-extinguisher')
    // },
    // {
    //     title: '',
    //     action: 'qr-code-scan',
    //     icon: duotone('qrcode')
    // }
  ];

  const { data, isLoading } = useSWR<ContentItem[]>(
    () =>
      '/api/content/getcontentbytype?contentType=news&locale=' +
      lang.locale() +
      '&preview=false',
    fetcher,
    {
      refreshInterval: 0,
      revalidateOnReconnect: true,
      keepPreviousData: true,
      onError: (error: any) => {
        console.error(error);
      },
    }
  );
  return (
    <>
      <Container fluid className='px-4 pt-4 mb-64'>
        {/* <Paper className="roundend-lg bg-white p-4 shadow-sm mb-4" withBorder>
            <Group grow align="center" justify="space-between" gap={0}>
                <FontAwesomeIcon icon={duotone('trophy')} size='2x' className='text-green-700' />
                <Text className="font-bold">{lang.Text('My Achivements')}</Text>
                <Button variant="light" color={Exagard_Colors._dark_green} radius={"xl"}
                    onClick={() => {navigate('/achivements')}}
                    rightSection={<FontAwesomeIcon icon={light('arrow-right')}/>}>
                    {lang.Text('View')}</Button>
            </Group>
        </Paper> */}

        {/* <Paper className="roundend-lg bg-white p-4 shadow-sm mb-4" withBorder>
            <Group grow align="center" justify="space-between">
                <Text className="font-bold">{lang.Text('Round')}</Text>
                <Button variant="filled" color={Exagard_Colors._dark_green}
                    onClick={() => {navigate('/round/')}}
                    rightSection={<FontAwesomeIcon icon={light('arrow-right')}/>}>
                    {lang.Text('Continue')}</Button>
            </Group>
        </Paper> */}

        {/* <Divider variant="dotted" color="white" className="my-4"/> */}
        <SimpleGrid
          cols={{ base: 1, sm: 2, lg: 3, xl: 4 }}
          spacing={{ base: 10, sm: 'xl' }}
          verticalSpacing={{ base: 'md', sm: 'xl' }}
          className='p-0 pb-64 mt-8'
        >
          {data?.map((item, key) => {
            return (
              <Card
                shadow='sm'
                radius='md'
                withBorder
                className='bg-white mb-4'
                style={{ zIndex: '15' }}
                key={key}
                onClick={() => {
                  navigate(item.url);
                }}
              >
                <Card.Section>
                  <Image
                    className='min-h-[260px] max-h-[260px]'
                    src={item.media?.url}
                    height={160}
                    alt={item.media?.title}
                  />
                </Card.Section>

                <Group justify='space-between' mt='md' mb='xs'>
                  <Text fw={700}>{item.caption}</Text>
                  <Badge color='green' variant='light'>
                    {item.shortDescription}
                  </Badge>
                </Group>

                <Text size='sm' c='dimmed'>
                  {renderMarkdown(item.description)}
                </Text>

                {/* {item.actions?.map((action, key) => { 
                return <Button variant="light" color="blue" fullWidth mt="md" radius="md">
                {action.title}
            </Button>} )} */}
              </Card>
            );
          })}
        </SimpleGrid>
      </Container>
      <AppMenu actions={actions}></AppMenu>
    </>
  );
};
