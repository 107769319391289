
import useSWR from "swr";
import { fetcher, Http } from "../Fetcher";
import { GlobalAppsettings } from "../../GlobalSettings";
import { useLanguage } from "../language/Language";
import { ContentItem } from "./api/Content";

export interface IContentItemProps {
    contentID: string
    locale?: string
    preview?: boolean
}

export const useContent = (props?: IContentItemProps) => {
    const lang = useLanguage();

    const { data, isLoading } = useSWR<ContentItem>( 
        () => '/api/content/getcontent?id=' + props?.contentID + '&locale=' + props?.locale
        + '&preview=false'
    , fetcher, {
        refreshInterval: 0,
        keepPreviousData: true
    });

    const getContentItem = async ( contentID: string, locale? : string, preview?: boolean) : Promise<ContentItem>  => {
        let l = locale ? locale : lang.locale();
        return fetch(GlobalAppsettings.BaseUrl + '/api/content/getcontent?id=' + contentID + '&locale=' + l
        + '&preview=' + preview, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            }
          })
          .then((response) => response.json());
    }

    return {
        getContentItem,
        contentItem: data,
        loading: isLoading,
    }
}

