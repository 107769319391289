import useSWR from "swr";
import { useLanguage } from "../../common/language/Language";
import { DashboardGrid } from "../../common/components/dashboard/DashboardGrid";
import { useEffect, useState } from "react";
import { Card, Group, Badge, Paper, Text, SimpleGrid, Image, CardSection, Button, Loader, Alert, Center, Transition } from "@mantine/core";
import { renderMarkdown } from "../../common/content/RenderMarkdown";
import { ContentItem } from "../../common/content/api/Content";
import { useNavigate } from "react-router-dom";
import { Exagard_Colors, GlobalAppsettings } from "../../GlobalSettings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone, light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { ActionItem, AppMenu } from "./components/AppMenu";
import { ResultBase } from "../../common/api/common";
import { TrainingItem } from "./api/TrainingApi";
import { fetcher } from "../../common/Fetcher";
import { useLocalStorage } from "@mantine/hooks";


export interface RoundInfoItem {
    id: string;
    imageUrl: string;
    logoUrl?: string;
    title: string;
    description: string;
    tag?: string;
}

export const TrainingCard = (props: { item: RoundInfoItem}) => {
    const lang = useLanguage();
    const navigate = useNavigate();

    return <>
    <Card shadow="sm" radius="md" 
            withBorder 
            className='bg-white mb-4' 
            style={{zIndex: '15'}} 
            key={'key'}
            // onClick={() => {navigate('/round/' + props.item.id)}}
            >
            <Card.Section>
                <Image
                    src={props.item.imageUrl}
                    height={160}
                    alt={'round-logo'}
                    className="min-h-[260px] max-h-[260px]"
                    fit="cover"
                />
                {props.item.logoUrl &&
                <div className="absolute top-0">
                    <Image
                        src={props.item.logoUrl}
                        fit="cover"
                    />
                </div>}
            </Card.Section>

            <Group justify="space-between" mt="md" mb="xs">
                <Text fw={700}>{props.item.title}</Text>
                {props.item.tag &&
                <Badge color={Exagard_Colors._green} variant="filled">
                {props.item.tag}
                </Badge>}
            </Group>
            <Text size="sm" c="dimmed">
                {renderMarkdown(props.item.description)}
            </Text>
            <CardSection className="p-4">
                <Group justify="right">
                    <Button variant="outline" radius={"xl"} color={Exagard_Colors._dark_green}
                        size="lg"
                        onClick={() => {navigate('/round/' + props.item.id)}}
                        rightSection={<FontAwesomeIcon icon={light('arrow-right')}/>}
                        >
                        {lang.Text('Start round')}</Button>
                </Group>
            </CardSection>
        </Card>
    </>
}


export const TrainingList = (props: {orgId: string}) => {
    const lang = useLanguage();
    const [error, setError] = useState<string>('');
     
    const [rounds, setRounds] = useLocalStorage<TrainingItem[]>({key: GlobalAppsettings.AppId + '-rounds', defaultValue: []});
    
    const { data, isLoading } = useSWR<ResultBase<TrainingItem[]>>(
        () =>  '/api/training/GetTrainingForOrg?orgId=' + props.orgId
       , fetcher, {
            refreshInterval: 0, 
            keepPreviousData: true,
            onError:  (error: any) => {
                console.error(error);
            }
        });

    useEffect(() => {
        if (data?.success) {
            setRounds(data.result);
            setError('');
        }
        else
        {
            setError(data?.errorMessage || 'Error');
        }
    }, [data, setRounds]);

    const actions : ActionItem[] = [
        {
            title: '',
            action: '/',
            icon: duotone('home')
        },
        {
            title: '',
            action: 'qr-code-scan',
            icon: duotone('qrcode')
        }
    ];


    return (<>
    {isLoading ? <Center className="p-56"><Loader color={Exagard_Colors._green} /></Center>:
    <>
    <div className="p-2">
        {error &&
        <Alert variant="light" 
            color="red" 
            title={lang.Text('Something went wrong')}>{lang.Text(error)}</Alert>
        }
    </div>
    <SimpleGrid
      cols={{ base: 1, sm: 2, lg: 3, xl: 4 }}
      spacing={{ base: 10, sm: 'xl' }}
      verticalSpacing={{ base: 'md', sm: 'xl' }}
      className="p-4 mb-64"
    >
        {rounds?.map((item, key) => {
            return <TrainingCard key={key} item={
                {
                    id: item.id,
                    imageUrl: item.imageUrl,
                    logoUrl: item.logoUrl,
                    title: item.title,
                    description: item.description,
                    tag: item.tag
                }
            }/>
        })} 
    </SimpleGrid>
    <div className="pb-64"></div>
    </>
    }

    <AppMenu actions={actions}></AppMenu>

    </>
    )

}