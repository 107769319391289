

export const usePWA = () => {

    const registerSW = () => {

        if ('serviceWorker' in navigator) {
            window.addEventListener('load', () => {
                navigator.serviceWorker.register('/sw.js')
                    .then(registration => {
                        console.log('SW registered: ', registration);
                    })
                    .catch(registrationError => {
                        console.log('SW registration failed: ', registrationError);
                    });
            });
        }
    }

    const unregisterSW = () => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready.then(registration => {
                registration.unregister();
            });
        }
    }

    const checkSW = () => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
                for (let registration of registrations) {
                    registration.unregister();
                }
            });
        }
    }

    const setBadge = (count: number) => {
        if ('setAppBadge' in navigator) {
            (navigator as any).setAppBadge(count);
        }
    }

    const clearBadge = () => {
        if ('clearAppBadge' in navigator) {
            (navigator as any).clearAppBadge();
        }
    }

    const RequestNotificationPermission = () => {
        if ('Notification' in window) {
            Notification.requestPermission(function (result) {
                if (result === 'granted') {
                    navigator.serviceWorker.ready.then(function (registration) {
                        registration.showNotification('Notification with ServiceWorker');
                    });
                }
            });
        }
    }

    return {
        registerSW,
        unregisterSW,
        checkSW,
        setBadge,
        clearBadge,
        RequestNotificationPermission
    }

}