/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ContentItem } from "../api/Content";
import moment, { lang } from "moment";
import { useLanguage } from "../../language/Language";
import { usePubsub } from "../../pubsub/usePubsub";
import { ActionButton } from "./ActionButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  ActionIcon,
  Avatar,
  Button,
  Container,
  Grid,
  rem,
  SimpleGrid,
  Skeleton,
  useMantineTheme,
} from "@mantine/core";
import { Exagard_Colors } from "../../../GlobalSettings";
import { useNavigate } from "react-router-dom";

export const PageByline = (props: { content?: ContentItem }) => {
  const lang = useLanguage();
  const app = usePubsub();

  const navigate = useNavigate();

  if (props.content === undefined) {
    return <></>;
  } else
    return (
      <div className="bg-white py-5">
        <div className="flex space-x-3">
          <div className="flex-shrink-0">
              <ActionIcon
                variant="filled"
                size="lg"
                color={Exagard_Colors._green}
                radius={"xl"}
                onClick={() => {
                  navigate("/");
                }}
              >
                X
              </ActionIcon>
          </div>
          <div className="min-w-0 flex-1">
            <div className="text-sm font-medium text-gray-900">
              <a href="#" className="hover:underline">
                {props.content.author}
              </a>
            </div>
            <div className="text-sm text-gray-500">
              {/* <a href="#" className="hover:underline"> */}
              {moment(props.content.sys.updatedAt).format("L") +
                " " +
                moment(props.content.sys.updatedAt).format("LT")}
              {/* </a> */}
            </div>
          </div>
          <div className="flex-shrink-0 self-center flex"></div>
        </div>
      </div>
    );
};
