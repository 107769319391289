import React from 'react';

import { useLanguage } from '../../common/language/Language';
import { useDisclosure, useTimeout } from '@mantine/hooks';
import {
  ActionIcon,
  Button,
  Center,
  Drawer,
  Group,
  Modal,
  Text,
} from '@mantine/core';
import { Exagard_Colors, GlobalAppsettings } from '../../GlobalSettings';
import { usePubsub, useSubscribe } from '../../common/pubsub/usePubsub';

import { QrReader } from 'react-qr-reader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';

export interface QRScannerProps {
  onScanned?: (data: string) => void;
  demoMode?: boolean;
  dialog?: boolean;
}

export const QRScanner = (props: QRScannerProps) => {
  const lang = useLanguage();
  const [data, setData] = React.useState('');
  const [info, setInfo] = React.useState('');
  const [scanned, setScanned] = React.useState(false);

  const App = usePubsub();

  const [opened, { open, close }] = useDisclosure(false);

  useSubscribe('qr-code-scan', (msg: string, data?: any) => {
    setData('');
    if (data !== undefined && data.info) {
      setInfo(data.info);
    }
    setScanned(false);
    clear();
    open();
  });

  const extractIdFromQrCode = (qrCode: string) => {
    if (qrCode.startsWith('http')) {
      let id = qrCode.split('/').pop();
      return id;
    } else {
      return qrCode;
    }
  };

  const scanner = (result: any, error: any) => {
    if (!!result) {
      console.info(result);
      let id = extractIdFromQrCode(result?.getText());
      if (id === undefined) return;

      console.info('QR-Code scanned: ' + id);

      setData(id);
      setScanned(true);

      App.publish('qr-code-scanned', id);
      if (props.onScanned !== undefined) {
        props.onScanned(id);
      }
      start();
    } else {
      //setScanned(false);
    }
  };

  const { start, clear } = useTimeout(() => close(), 1000);

  const renderScanner = () => {
    return (
      <div
        onClick={() => {
          if (props.demoMode) {
            if (props.onScanned) props.onScanned('test');
            close();
          }
        }}
      >
        <ActionIcon
          className='absolute top-2 right-0'
          variant='transparent'
          color={'white'}
          size='xl'
          onClick={() => close()}
        >
          X
        </ActionIcon>
        <Group className='my-4' align='center' justify='center'>
          {/* <Text>{lang.Text('Scan QR-code')}</Text> */}
        </Group>
        <QrReader
          scanDelay={300}
          onResult={(result, error) => {
            scanner(result, error);
          }}
          constraints={{
            facingMode: 'environment',
            width: 400,
            height: 400,
          }}
          // className="w-full"
          videoContainerStyle={{ width: '100%', height: '100%' }}
          videoStyle={{ borderRadius: '2rem', border: '1px solid white' }}
          //videoContainerStyle={{ borderRadius: '2rem' }}
        />
        <div className='fixed top-[20%] left-[20%] right-[20%] h-64 text-center'>
          {scanned ? (
            <Center className='h-full'>
              <FontAwesomeIcon
                icon={duotone('check-circle')}
                size='10x'
                className='text-green-400'
              />
            </Center>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {props.dialog ? (
        <Modal
          opened={opened}
          onClose={close}
          size={'50%'}
          withCloseButton={false}
          centered={true}
          overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
          padding={'lg'}
          styles={{
            content: {
              backgroundColor: Exagard_Colors._background,
              color: 'white',
              padding: 10,
              borderRadius: '1rem',
            },
          }}
        >
          {renderScanner()}
        </Modal>
      ) : (
        <Drawer
          opened={opened}
          onClose={close}
          overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
          withCloseButton={false}
          position='bottom'
          size={'75%'}
          transitionProps={{ duration: 700, transition: 'slide-up' }}
          styles={{
            content: {
              backgroundColor: Exagard_Colors._background,
              color: 'white',
              padding: 0,
              borderRadius: '1rem 1rem 0 0',
            },
            // inner: { fontSize: 20 },
          }}
          // className={"backdrop-blur-md backdrop-brightness-150 bg-green-800/80"}
        >
          {renderScanner()}
        </Drawer>
      )}
    </>
  );
};
