
import { Route } from "react-router-dom"
import useSWR from "swr"
import { fetcher, Http } from "../Fetcher"
import { PageItem } from "./api/Content"
import { ContentPage } from "./ContentPage"
import { ScrollArea } from "@mantine/core"
import { useViewportSize } from "@mantine/hooks"

export interface IContentItemProps {
    contentID: string
    locale: string
    preview?: boolean
}

export interface IContentRoutesProps {
    contentType: string,
    locale: string
    preview: boolean
}


export const ContentRoutes = (props: IContentRoutesProps) => {
    const { height, width } = useViewportSize();

    const { data } = useSWR<PageItem[]>(
        () =>  '/api/content/getpages?contentType=' + props.contentType + '&locale=' + props.locale
            + '&preview=' + props.preview
       , fetcher, {
            refreshInterval: 0, 
            keepPreviousData: true,
            onError:  (error: any) => {
                console.error(error);
            }
        });
    
    // useEffect(() => {
    //     // console.info(data);
    // },data);

    return data?.map((item, key) =>
    <Route
        key={key}
        path={item.url}
        element={<ScrollArea h={height} ><ContentPage key={key} className="lg:mx-16 md:mx-6 sm:mx-1 my-12" 
        contentType="contentful" contentID={item.sys.id}/></ScrollArea>} 
    />);
}
     