import { Avatar, Grid, LoadingOverlay, Image, Paper, SimpleGrid, Skeleton, useMantineTheme, Stack, Group,
     Overlay, Container, Title, Button, Text, rem, ActionIcon, AppShell, ScrollArea} from "@mantine/core";
import useSWR from "swr";
import { fetcher } from "../Fetcher";
import { Language, useLanguage } from "../language/Language";
import { ActionRef, ContentItem } from "./api/Content";
import { PageByline } from "./components/PageByline";
import { renderMarkdown } from "./RenderMarkdown";
import { Footer } from "./Footer";
import { ActionButton } from "./components/ActionButton";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Exagard_Colors } from "../../GlobalSettings";
import { useNavigate } from "react-router-dom";
import { ActionItem, AppMenu } from "../../modules/training/components/AppMenu";
import { useEffect, useState } from "react";
import { IconName,} from "@fortawesome/fontawesome-svg-core";

export interface IContentProps {
    children?: any
    style?: any
    className?: any
    contentID?: string
    contentType?: string
    preview?: boolean
    showLoading?: boolean
}


export const ContentPage = (props: IContentProps = {contentType: 'contentful', showLoading: false}) => {
    const lang = useLanguage();
    const locale = Language.GetLocale();
    let navigate = useNavigate();
    
    const theme = useMantineTheme();
    const SECONDARY_COL_HEIGHT = `calc(100vh / 2 - ${theme.spacing.md} / 2)`;

    const [actions, setActions] = useState<ActionItem[]>([]);
    
    const { data, isLoading: loading } = useSWR<ContentItem>(
        () => '/api/content/getcontent?id=' + props.contentID + '&locale=' + locale
            + '&preview=' + props.preview
        , fetcher, {
            refreshInterval: 0, 
            keepPreviousData: true
        });

    useEffect(() => {
        if (data?.actions) {
            let items: ActionItem[] = [];
            data?.actions.forEach((item: ActionRef) => {
                switch (item.icon) {
                    case "home":
                        items.push({
                            title: item.title,
                            action: item.url,
                            icon: duotone('home')
                        });
                        break;
                    case "back":
                        items.push({
                            title: item.title,
                            action: 'back',
                            icon: duotone('arrow-left')
                        });
                        break;
                    case "forward":
                        items.push({
                            title: item.title,
                            action: 'forward',
                            icon: duotone('arrow-right')
                        });
                        break;
                    case "support":
                        items.push({
                            title: item.title,
                            action: 'forward',
                            icon: duotone('circle-question')
                        });
                        break;
                }
               
            });
            setActions(items);
        }
    },[data]);
    
    const renderArticleHero = () => {
        
    return (<>
    <ScrollArea>
    <div className={""} style={{backgroundImage:'url(' + data?.media?.url + ')',}}>
      <Overlay
        gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)"
        opacity={1}
        zIndex={0}
      />
      <Container className={""}>
        <Title className={""}>{data?.caption}</Title>
        <Text className={""} size="xl" mt="xl">
            {renderMarkdown(data?.description,".description",'text-white')}
        </Text>
        <ActionButton action={data?.actions[0]} className={""} size="xl" /> 
      </Container>
      </div>
      </ScrollArea>
      </>);
    }

    const renderNews = () => {
        return (<><Stack align="stretch" >
        {loading ? <Skeleton radius="md" animate={true} className="min-h-[100vh]"/>:<>
            
            {renderArticleHero()}
            
            </> }
            <div className="mx-auto sm:px-4 xl:px-16 py-12">
                {renderMarkdown(data?.copy,".copy")}
            </div>
        </Stack>

        {renderMarkdown(data?.slug,".slug")}
        
        </> )
    }


    const renderArticle1 = () => {
        return (<><Stack align="stretch">
        {loading ? <Skeleton radius="md" animate={true} className="min-h-[100vh]"/>:<>
            {renderArticleHero()}
            {/* <div className="py-2 mt-8 text-7xl font-extrabold tracking-tight text-gray-900 ">
                {data?.caption}
            </div> */}
            </> }
            <div className="mx-auto sm:px-4 xl:px-16 py-12">
                {renderMarkdown(data?.copy,".copy")}
            </div>
        </Stack>
        <div className="mt-4 px-6">
            <hr/>
            {renderMarkdown(data?.slug,".slug","text-sky-700")}
        </div>
        </> )
    }

    const renderArticle2 = () => {
        return (<Stack align="center">
        {loading ? <Skeleton radius="md" animate={true} className="min-h-[100vh]"/>:<>
            <div className="py-2 mt-8 text-5xl font-extrabold tracking-tight text-gray-900 ">
                {data?.caption}
            </div>
            <div className="mt-4">
            {renderMarkdown(data?.description,".description")}
            </div>
            </> }
            <div className="mx-auto sm:px-4 xl:px-16">
                {renderMarkdown(data?.copy,".copy")}
            </div>
        </Stack>
        
        )
    }

    const renderMessage = () => {
        return (
        <Group grow className="" align="stretch">
            {data?.media? <div >
                <Image fit="cover" radius="md" className="mt-12 opacity-80 saturate-50"
                    src={data?.media?.url} alt={data?.media?.title}  />
            </div> : null}            
            <Stack align="center">
            {loading ? <Skeleton radius="md" animate={true} />:<>
                <div className="py-2 mt-8 text-5xl font-extrabold tracking-tight text-gray-700 ">
                    {data?.caption}
                </div>
                <div className="mt-4">
                    {renderMarkdown(data?.description,".description")}
                </div>
                </> }
                <div className="mx-auto sm:px-4 xl:px-16">
                    {renderMarkdown(data?.copy,".copy")}
                </div>
            </Stack>
        </Group>
        )
    }

    const renderPage = () => {
        return (<SimpleGrid className="" spacing={'45px'} cols={{ xl: 2, md: 1 }} >
            <Grid gutter="md">
            {loading ? <Grid.Col >  <Skeleton radius="md" animate={true} className="min-h-[100vh]"/> </Grid.Col>:
            <Grid.Col >
                <div className="py-2 text-5xl font-extrabold tracking-tight text-gray-900 ">
                    {data?.caption}
                </div>
                <div className="text-gray-400 my-4" style={{fontFamily: 'gotham_roundedlight'}}>
                {renderMarkdown(data?.description,".description")}
                </div>
            </Grid.Col>}
            <Grid.Col >
                {renderMarkdown(data?.copy,".copy")}
            </Grid.Col>
        </Grid>
          <Stack>
            {loading ? <Skeleton height={SECONDARY_COL_HEIGHT} radius="md" animate={true} /> :
           data?.media? <div>
                <Image fit="cover" radius="md" className="mt-24 opacity-80 saturate-50"
                    src={data?.media?.url} alt={data?.media?.title}  />
                </div>:<></>
            }
            {loading ? <Skeleton height={SECONDARY_COL_HEIGHT} radius="md" animate={true} /> :
            data?.slug ? <>
                {renderMarkdown(data.slug, ".slug")}
            </>:<></>}
            </Stack>
        </SimpleGrid>)
    }

    const renderSlug = () => {
        return (<>
         {data?.slug ?
        <div className="relative bg-bra">
            <div className="absolute inset-0">
            {data?.images.length > 0 &&
                <img
                className="w-full h-full object-cover"
                src={data?.images.length > 0 ? data?.images[0].url
                : ""}
                alt=""
                />}
                <div className="absolute inset-0 bg-gray-500 mix-blend-multiply" aria-hidden="true" />
            </div>
            <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
                <div className="mt-6 text-xl max-w-3xl px-14">
                    {renderMarkdown(data.slug, data?.title + ".slug",'text-white')}
                </div>
            </div>
            </div>
        : <></>}
        </>)
    }

    const renderTemplateType = () => {
        //console.log("templateId->",data?.templateId);
        switch (data?.templateId) {
            case 'page':
                return <>
                    <PageByline content={data} />
                    {renderPage()}
                </> 
            case 'article':
            case 'article1':
                return  <>
                    <PageByline content={data} /> 
                    {renderArticle1()}
                </>
            case 'article2':
                return  <>
                    <PageByline content={data} /> 
                    {renderArticle2()}
                </>
            case 'news':
                return  <>
                    <PageByline content={data} /> 
                    {renderNews()}
                </>
            case 'message':
                return  <>
                    {renderMessage()}
                </>
            default:
                return  <>
                    <PageByline content={data} /> 
                    {renderPage()}
            </>
        }
    }

   

    return (<><div style={props.style} className={props.className}>
    <Paper shadow="md" className="min-h-[100vh] px-4 ">
        <div className='lg:px-14 md:px-10 sm:px-2 px-2 pt-4 pb-8'>
            {renderTemplateType()}
        </div>
        {/* {renderSlug()} */}
    </Paper>
    </div>
    {actions.length >0 ?
        <AppMenu actions={actions}></AppMenu> : <></>
    }
    </>
    );
  }
  