import { useNavigate, useParams } from "react-router-dom";
import { useLanguage } from "../../common/language/Language";
import { Alert, Center, Loader } from "@mantine/core";
import { useEffect, useState } from "react";
import { Exagard_Colors, GlobalAppsettings } from "../../GlobalSettings";
import { Http, fetcher } from "../../common/Fetcher";
import { ResourceAction } from "./api/ResourceApi";
import { ResultBase } from "../../common/api/common";

export const ResourceModule = () => {
    const lang = useLanguage();
    const { resourceId } = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState<string | undefined>(undefined);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {

        if (resourceId!==undefined && resourceId!==null && resourceId!=='') { 

            fetcher( GlobalAppsettings.BaseUrl + '/api/resource/getresource?id=' + resourceId, Http.GET)
            .then((result: ResultBase<ResourceAction>) => {
                if (result.success) {
                    let action = result.result;

                    switch (action?.type) {

                        case 'navigate':
                            navigate(action?.refs![0]);
                            break;
                        case 'redirect':
                            window.location.href = action?.refs![0];
                            break;
                        case 'random':
                            //radomize between 1-5
                            let num = Math.floor(Math.random() * 5) + 1;
                            switch (num) {
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                    navigate(action?.refs![0]);
                                    break;
                                case 5:
                                    navigate(action?.refs![1]);
                                    break;
                            }
                            break;
                        default:
                            setError('Action Type not supported');
                            break;
                    }

                } else {
                    setError(result.errorMessage);
                }
            }).catch((error: any) => {
                setError(error);
            }).finally(() => {
                setLoading(false);
            });
        }
    }, []);

    return (<>
        {isLoading ?
         <div className="p-16" style={{ height: '100vh' }}>
            <Center><Loader color={Exagard_Colors._background} size={'lg'}/></Center>
        </div>
         :
        <div className="p-16">
        <Alert variant="light" 
            color="red" 
            title={lang.Text('Something went wrong')}>{lang.Text(error)}</Alert>
        </div>
        } 
    </>);
}