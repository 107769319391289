
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import packageJson from '../package.json';
import * as Sentry from "@sentry/react";
import React from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

const { REACT_APP_IS_DEV } = process.env;
const { REACT_APP_BASE_URL } = process.env;
const { REACT_APP_DEFAULT_LANGUAGE } = process.env;
const { REACT_APP_AUTH_PROVIDER } = process.env;
const { REACT_APP_STRIPE_KEY } = process.env;

const API_URL  = process.env.API_URL;
const APP_URL  = process.env.APP_URL;

console.info(packageJson.version);

export class GlobalAppsettings {

  protected static api_key: string = "*l1^-;L^p1|G%956n_*Q520b";
  
  //version id
  static AppId: string = "exagard-eva-" + packageJson.version;

  static AppInsights: string = "9f260433-df6f-4f7e-9ba4-981ab26da62a";

  static IsDevelopment: boolean =  REACT_APP_IS_DEV ? (REACT_APP_IS_DEV.toLowerCase() === 'true') : false;

  static BaseUrl: string = REACT_APP_BASE_URL ? REACT_APP_BASE_URL : '';

  static InterCom: string = "";

  static AuthProvider: string = REACT_APP_AUTH_PROVIDER ? REACT_APP_AUTH_PROVIDER : 'auth0';

  static DefaultLang: string = REACT_APP_DEFAULT_LANGUAGE ? REACT_APP_DEFAULT_LANGUAGE : 'en-GB';

  static StripeKey: string = REACT_APP_STRIPE_KEY ? REACT_APP_STRIPE_KEY : "pk_test_51IsRksABc38EtH9J52zPbml0d4WYIB7pkOkYZY71qmdeSNp9YkAiCbCFfj7f8FvwBVYVV8BK8uGaEfb6qFUbJNbZ00z4mii47Z";

  static Audience: string = `https://api.portal.exagard.se`;
  
  static UseMap: boolean = true;

}

const APP_USER = GlobalAppsettings.AppId + "-user";
const APP_USER_SELECTED_PROGRAM = APP_USER + "-selected-program";
const APP_LANGUAGE = GlobalAppsettings.AppId + "-language";
const APP_CURRENT_LANGUAGE = GlobalAppsettings.AppId + "-current-language";

console.info(REACT_APP_BASE_URL);
console.info(REACT_APP_DEFAULT_LANGUAGE);
console.info(GlobalAppsettings.AppId);

export const StorageKeys = {
  APP_USER,
  APP_USER_SELECTED_PROGRAM,
  APP_LANGUAGE,
  APP_CURRENT_LANGUAGE,
};

export const appInsights = new ApplicationInsights({ config: {
  instrumentationKey: GlobalAppsettings.AppInsights
} });

if (GlobalAppsettings.IsDevelopment) {
  
  console.info("Development Mode");

}
else{
  //appInsights.loadAppInsights();
  //console.info("AppInsights Loaded: ", GlobalAppsettings.AppInsights);
  //appInsights.trackPageView({name: "Portal"});

  Sentry.init({
    dsn: "https://d8f97ce27fd738f6754ff2bf8c814bc6@o4505940743487488.ingest.sentry.io/4505940782809088",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.2,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  console.log = function () {};
  console.info = function () {};
  console.warn = function () {};
  console.error = function (...data: any[]) {
    //appInsights.trackException({exception: new Error(data.join(' '))});
    Sentry.captureException(new Error(data.join(' ')));
  };
} 

export class Exagard_Colors  {
  static _light_gray:string = '#F8F9FA';
  static _gray:string = '#63666A';
  static _medium_gray:string = '#A0A0A0';
  static  _light_green:string = '#A4D65E';
  static  _green:string = '#64A70B';
  static  _dark_green:string = '#035F1D';
  static  _dark_green_transparent:string = '#035F1D';
  static  _background:string = '#1f2937';
  static  _black:string = '#1f2937';
 }

export const localStorageCacheProvider = () => {
  const map = new Map(JSON.parse(localStorage.getItem(GlobalAppsettings.AppId + '-app-cache') || '[]'))
  window.addEventListener('beforeunload', () => {
    const appCache = JSON.stringify(Array.from(map.entries()))
    localStorage.setItem(GlobalAppsettings.AppId + '-app-cache', appCache)
  })
  return map
}

export const sessionStorageCacheProvider = () => {
  const map = new Map(JSON.parse(sessionStorage.getItem(GlobalAppsettings.AppId + '-app-cache') || '[]'))
  window.addEventListener('beforeunload', () => {
    const appCache = JSON.stringify(Array.from(map.entries()))
    sessionStorage.setItem(GlobalAppsettings.AppId + '-app-cache', appCache)
  })
  return map
}
